import { createColumnHelper } from '@tanstack/react-table';
import { CustomAmenity } from 'models/Amenity';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useServerFilteredTable from 'components/table/hooks/useServerFilteredTable';
import CommonTable from 'components/table/CommonTable';
import ActionsCell from './cells/ActionsCell';
import { propertyCustomAmenitiesQuery } from './CustomAmenities.utils';

export const PAGE_SIZE = 10;

const CustomAmenitiesTable = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<CustomAmenity>();

  const columns = [
    columnHelper.accessor('name', {
      header: t('common.name'),
    }),
    columnHelper.display({
      id: 'actions',
      header: t('common.actions'),
      cell: ActionsCell,
    }),
  ];

  const { tableInstance, isLoading } = useServerFilteredTable({
    columns,
    getQuery: (options) =>
      propertyCustomAmenitiesQuery({ propertyUid, ...options }),
    pageSize: PAGE_SIZE,
  });

  return (
    <CommonTable
      tableInstance={tableInstance}
      isLoading={isLoading}
      emptyState={t('pageProperty.amenities.customAmenities.emptyTable')}
      data-testid="custom-amenities-table"
    />
  );
};

export default CustomAmenitiesTable;
