import { DeepPartial } from 'react-hook-form';
import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { AREA_UNIT_TYPE } from 'models/AreaUnitType';
import { CURRENCIES_WITHOUT_NONE, Currency } from 'models/Currency';
import { PropertyType } from 'models/PropertyType';
import {
  basePropertyAddressSchema,
  propertyCapacityDetailsSchema,
  propertyDetailsSchema,
} from '../../common/Common.schema';
import { PropertyDetails } from '../../mainSettings/PropertySettingsMainTab.types';

export const addSinglePropertySchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    capacityDetails: propertyCapacityDetailsSchema(t),
    propertyAddress: basePropertyAddressSchema(),
    propertyDetails: propertyDetailsSchema(t),
    propertyPricingFeesTaxes: Yup.object({
      cleaningFee: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      currency: Yup.mixed<Currency>().oneOf(CURRENCIES_WITHOUT_NONE).required(),
      nightlyBasePrice: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .positive()
        .min(10)
        .required(),
      securityDeposit: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .optional()
        .nullable(),
      taxRate: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .optional()
        .nullable(),
    }),
  });

export type AddSinglePropertyFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof addSinglePropertySchema>>
>;

export type SinglePropertyCreationPayload = AddSinglePropertyFormValues & {
  propertyDetails?: Partial<PropertyDetails>;
};

export function getDefaultFormValues(
  propertyTypes: PropertyType[],
): AddSinglePropertyFormValues {
  return {
    capacityDetails: {
      baseGuests: 1,
      maxGuests: 1,
    },
    propertyAddress: {
      countryCode: 'US',
      state: 'AL',
    },
    propertyDetails: {
      propertySizeUnit: AREA_UNIT_TYPE.SQUARE_METERS,
      propertyType: propertyTypes?.[0]?.type,
    },
    propertyPricingFeesTaxes: {
      cleaningFee: 0,
      currency: 'USD',
      nightlyBasePrice: 0,
      securityDeposit: 0,
      taxRate: 0,
    },
  };
}
