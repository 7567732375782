import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextField from 'components/forms/textField/TextField';
import useGetCurrencySymbol from 'hooks/useGetCurrencySymbol';
import { Currency } from 'models/Currency';
import {
  CollapseSectionStyled,
  FormSectionColumn,
} from '../../../common/Common.styles';
import PropertyFormFieldLabel from '../../../common/PropertyFormFieldLabel';

const PropertyCapacityDetails = ({ currency }: { currency: Currency }) => {
  const getCurrencySymbol = useGetCurrencySymbol();
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const isEditMode = !!propertyUid;

  const baseGuestTooltipContent = (
    <a
      href="https://help.hostfully.com/en/articles/8202150-quick-guide-to-your-property-settings"
      rel="noreferrer"
      target="_blank"
    >
      {t('pageProperty.mainSettings.capacityDetails.baseGuestsTooltip')}
    </a>
  );

  return (
    <CollapseSectionStyled
      data-testid="property-capacity-details"
      defaultExpanded
      label={t('pageProperty.mainSettings.capacityDetails.sectionTitle')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>TODO PMP-9694</FormSectionColumn>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.capacityDetails.baseGuests')}
              name="capacityDetails.baseGuests"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={baseGuestTooltipContent}
              min={1}
              type="number"
              required
            />
            <TextField
              label={t('pageProperty.mainSettings.capacityDetails.maxGuests')}
              name="capacityDetails.maxGuests"
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.capacityDetails.maxGuestsTooltip',
              )}
              min={1}
              type="number"
              required
            />
            {isEditMode && (
              <TextField
                label={
                  <PropertyFormFieldLabel name="capacityDetails.extraGuestFee">
                    {t(
                      'pageProperty.mainSettings.capacityDetails.extraGuestFee',
                    )}
                  </PropertyFormFieldLabel>
                }
                name="capacityDetails.extraGuestFee"
                colSmInput={7}
                colSmLabel={5}
                leftAddon={getCurrencySymbol(currency)}
                rightTooltip={t(
                  'pageProperty.mainSettings.capacityDetails.extraGuestFeeTooltip',
                )}
                min={0}
                type="number"
              />
            )}
          </FormSectionColumn>
        </Row>
      </fieldset>
    </CollapseSectionStyled>
  );
};

export default PropertyCapacityDetails;
