import { BaseSyntheticEvent } from 'react';
import { Form as BSForm, FormProps as BSFormProps } from 'react-bootstrap';
import {
  FieldValues,
  FormProvider as RHFProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormContextParts } from './Form.types';

interface Props<FormValues> {
  defaultValues?: { [key: string]: any };
  onSubmit: (
    data: FormValues,
    event: BaseSyntheticEvent | undefined,
    formContextParts: FormContextParts<FormValues>,
  ) => void;
  onInvalid?: (_: any) => void;
  schema?: { [key: string]: any };
  resolver?: any;
  children: any;
  [key: string]: any;
}

type FormProps = Omit<BSFormProps, 'onSubmit'>;

export const FormProvider = <FormValues extends FieldValues>({
  defaultValues,
  children,
  schema,
  resolver,
}: Omit<Props<FormValues>, 'onSubmit' | 'onInvalid'>) => {
  const methods = useForm({
    defaultValues,
    resolver: resolver
      ? yupResolver(resolver)
      : yupResolver(Yup.object(schema).required()),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });

  return <RHFProvider {...methods}>{children}</RHFProvider>;
};

export const Form = <FormValues extends FieldValues>({
  children,
  onSubmit,
  onInvalid,
  ...props
}: Pick<Props<FormValues>, 'children' | 'onSubmit' | 'onInvalid'> &
  FormProps) => {
  const { formState, handleSubmit, reset, setError } =
    useFormContext<FormValues>();

  const submitHandler = (
    data: FormValues,
    event: BaseSyntheticEvent | undefined,
  ) => {
    return onSubmit(data, event, { formState, reset, setError }); // must return in order to be able to await for async submits
  };

  return (
    <BSForm onSubmit={handleSubmit(submitHandler, onInvalid)} {...props}>
      {children}
    </BSForm>
  );
};

/**
 * This component is the default Form that should be used in the app.
 * It wraps the react-hook-form FormProvider and adds a validation schema as a prop.
 * Any new modifications in the UI and validation should be done here.
 */
const FormWithProvider = <FormValues extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  onInvalid,
  schema,
  resolver,
  ...rest
}: Props<FormValues> & FormProps) => {
  return (
    <FormProvider
      defaultValues={defaultValues}
      schema={schema}
      resolver={resolver}
    >
      <Form onSubmit={onSubmit} onInvalid={onInvalid} {...rest}>
        {children}
      </Form>
    </FormProvider>
  );
};

FormWithProvider.defaultProps = {
  defaultValues: {},
  resolver: null,
  schema: {},
  onInvalid: () => {},
};

FormProvider.defaultProps = {
  defaultValues: {},
  resolver: null,
  schema: {},
};

export default FormWithProvider;
