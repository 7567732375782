import styled, { css } from 'styled-components';
import { Button } from 'react-bootstrap';

export type ColorScheme = 'green'; // should be extended to our application styles needs.

const getColorSchemeCSS = (colorScheme: ColorScheme) => {
  const styles: Record<ColorScheme, any> = {
    green: css`
      background: #33cc99;
      border: 1px solid #1d9970;
      color: #fff;

      &:hover,
      &[disabled]:hover,
      &:active,
      &:focus,
      &:active:focus,
      &:active:hover {
        background: #2bbd8c;
        border: 1px solid #1d9970;
        color: #fff;
      }
    `,
  };

  return styles[colorScheme] || null;
};

export const StyledButton = styled(Button)<{
  $colorScheme?: 'green';
  $noBorder?: boolean;
  $bigRounded?: boolean;
}>`
  ${({ $colorScheme }) => getColorSchemeCSS($colorScheme)}

  ${({ $noBorder }) =>
    $noBorder &&
    `
    border: none;
  `}


${({ $bigRounded }) =>
    $bigRounded &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 35px;
      border-radius: 28px;
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0px;
      min-width: 180px;
      height: 56px;
      background: #33cc99;
      border: 1px solid #1d9970;
      color: #fff;

      &:hover,
      &:active,
      &:focus,
      &:active:focus,
      &:active:hover {
        background: #2bbd8c;
        border: 1px solid #1d9970;
        color: #fff;
      }
    `}
`;
