import usePropertySettingsInitData from '../usePropertySettingsInitData';
import PropertyFeesAndTaxes from './sections/propertyFeesAndTaxes/PropertyFeesAndTaxes';

const PropertySettingsFeesAndPoliciesTab = () => {
  usePropertySettingsInitData();

  return (
    <div>
      <PropertyFeesAndTaxes />
    </div>
  );
};

export default PropertySettingsFeesAndPoliciesTab;
