import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import FormWithProvider from 'components/forms/form/Form';
import useFetchPropertyChannelSettings from 'components/domain/channelSettings/useFetchPropertyChannelSettings';
import useFetchPropertyTypes from 'components/domain/property/useFetchPropertyTypes';
import PropertyStatus from 'components/domain/property/status/PropertyStatus';
import { PropertyBusinessType } from 'models/Properties';
import FormFooter from '../common/FormFooter';
import usePropertySettingsInitData from '../usePropertySettingsInitData';
import useFetchPropertyMainSettingsData from './useFetchPropertyMainSettingsData';
import AirbnbSettings from './sections/airbnbSettings/AirbnbSettings';
import BookingSettings from './sections/bookingSettings/BookingSettings';
import PropertyAddress from './sections/propertyAddress/PropertyAddress';
import PropertyDetails from './sections/propertyDetails/PropertyDetails';
import PropertyMiscInfo from './sections/propertyMiscInfo/PropertyMiscInfo';
import {
  getFormDefaultValues,
  propertySettingsMainTabSchema,
} from './PropertySettingsMainTab.schema';
import { PropertySettingsMainTabStyled } from './PropertySettingsMainTab.styles';
import PropertyVrboSettings from './sections/vrboSettings/PropertyVrboSettings';
import usePropertyMainSettingsFormSubmit from './usePropertyMainSettingsFormSubmit';
import PropertyCapacityDetails from './sections/capacityDetails/PropertyCapacityDetails';

const PropertySettingsMainTab = () => {
  const { t } = useTranslation();
  const { uid: propertyUid } = useParams();
  const { property, isLoading: isLoadingInitData } =
    usePropertySettingsInitData();

  const { isFetching: isLoadingChannelSettings } =
    useFetchPropertyChannelSettings(propertyUid);
  const { isLoading: isLoadingPropertyTypes } = useFetchPropertyTypes();
  const { isFetching, mainSettingsData } =
    useFetchPropertyMainSettingsData(propertyUid);

  const onSubmit = usePropertyMainSettingsFormSubmit(propertyUid);

  if (
    isLoadingInitData ||
    isFetching ||
    isLoadingPropertyTypes ||
    isLoadingChannelSettings
  ) {
    return <Loader />; // TODO replace with loading placeholder
  }

  return (
    <PropertySettingsMainTabStyled data-testid="main-settings-tab">
      {property?.businessType !== PropertyBusinessType.HOTEL && (
        <PropertyStatus property={property} />
      )}

      <FormWithProvider
        defaultValues={getFormDefaultValues(mainSettingsData)}
        resolver={propertySettingsMainTabSchema(t)}
        onSubmit={onSubmit}
        noValidate
      >
        <PropertyDetails />
        <PropertyAddress />
        {(property.businessType === PropertyBusinessType.STANDALONE_PROPERTY ||
          property.businessType === PropertyBusinessType.SUB_UNIT) && (
          <PropertyCapacityDetails currency={property?.pricing?.currency} />
        )}
        <BookingSettings />
        <PropertyMiscInfo />
        <AirbnbSettings />
        <PropertyVrboSettings />
        <FormFooter saveButtonLabel={t('pageProperty.saveMainSettings')} />
      </FormWithProvider>
    </PropertySettingsMainTabStyled>
  );
};

export default PropertySettingsMainTab;
