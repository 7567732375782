import { createGlobalStyle, css } from 'styled-components';
import SourceSansProLight from 'assets/fonts/SourceSansPro-Light.ttf';
import SourceSansProRegular from 'assets/fonts/SourceSansPro-Regular.ttf';
import theme from './theme';
import { ScrollableShadowStyles } from './ScrollableShadow.styles';

// BS5 utility classes that are not available in BS3 - this should be removed once migrated to BS5
const bootstrap5Adapter = css`
  .d-none {
    display: none !important;
  }

  .invisible {
    visibility: hidden !important;
  }
`;

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: SourceSansPro_light;
    src: url(${SourceSansProLight});
  }
  @font-face {
    font-family: SourceSansPro_regular;
    src: url(${SourceSansProRegular});
  } 

  html {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    color: ${theme.colors.fontDark} !important;
  }

  div.tooltip a {
    color: #fff;
  }

  ${bootstrap5Adapter}

  ${ScrollableShadowStyles}
`;

export default GlobalStyles;
