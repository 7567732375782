import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BulkEditIconSvg from 'assets/images/icons/bulk-edit.svg?react';
import Button from 'components/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { propertySettingsBulkSavableFields } from './Common.constants';

const FormFooterButtons = ({
  saveButtonLabel,
}: {
  saveButtonLabel: string;
}) => {
  const { isSubmitting, dirtyFields } = useFormState();
  const navigate = useNavigate();
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();

  const isEditMode = !!propertyUid;

  const isActuallyDirty = Object.keys(dirtyFields).length > 0;

  const isAnyBulkSavableFieldDirty =
    isEditMode &&
    Object.entries(dirtyFields).some(([sectionName, sectionDirtyFields]) =>
      Object.keys(sectionDirtyFields).some((fieldName) =>
        propertySettingsBulkSavableFields[sectionName]?.includes(fieldName),
      ),
    );

  return (
    <>
      {isEditMode && (
        <Button
          $colorScheme="green"
          data-testid="bulk-save-button"
          className="bulk-save"
          onClick={() => {}}
          disabled={isSubmitting || !isAnyBulkSavableFieldDirty}
        >
          <BulkEditIconSvg />
          {t('pageProperty.bulkSave')}
        </Button>
      )}
      {!isEditMode && (
        <Button
          data-testid="cancel-button"
          onClick={() => navigate('/properties')}
          disabled={isSubmitting}
        >
          {t('common.cancel')}
        </Button>
      )}
      <Button
        data-testid="save-button"
        bsStyle="primary"
        disabled={isSubmitting || !isActuallyDirty}
        type="submit"
      >
        {isSubmitting
          ? t('common.saving')
          : saveButtonLabel ?? t('common.save')}
      </Button>
    </>
  );
};

export default FormFooterButtons;
