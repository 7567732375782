import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import Button from 'components/button/Button';

export const NonRefundableRateDiscountContainer = styled(Col)`
  padding-right: 0;

  .form-field-container {
    margin-left: -15px;
    margin-top: -25px;
  }
`;

export const ViewAirbnbResolutionsStyled = styled(Button)`
  align-self: flex-end;
  font-weight: bold;
  width: fit-content;
`;
