import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { Glyphicon } from 'react-bootstrap';
import { SectionHeaderContainer } from '../PropertySettingsFeesAndPoliciesTab.styles';

interface SectionHeaderProps {
  title: string;
  id: string;
  tooltip?: string | React.ReactNode;
  button?: {
    label: string;
    onClick: () => void;
  };
}

const SectionHeader = ({ title, id, tooltip, button }: SectionHeaderProps) => {
  return (
    <SectionHeaderContainer>
      <h3>{title}</h3>
      {tooltip && (
        <Tooltip id={`${id}-tooltip`} text={tooltip}>
          <Glyphicon glyph="info-sign" />
        </Tooltip>
      )}
      {button && (
        <Button bsStyle="primary" onClick={button.onClick}>
          <Glyphicon glyph="plus" />
          {button.label}
        </Button>
      )}
    </SectionHeaderContainer>
  );
};

SectionHeader.defaultProps = {
  tooltip: undefined,
  button: undefined,
};

export default SectionHeader;
