export const propertySettingsBulkSavableFields = {
  airbnbSettings: [
    'bookingType',
    'cancellationPolicy',
    'checkInTimeStartFlexible',
    'checkInTimeEnd',
    'nonRefundableRateEnabled',
  ],
  bookingSettings: [
    'bookingLeadTime',
    'bookingType',
    'bookingWindow',
    'checkInTime',
    'checkoutTime',
    'daysOfTheWeekToCheckInOn',
    'fullPaymentTiming',
    'maximumStay',
    'minimumStay',
    'minimumWeekendStay',
    'percentageUponReservation',
    'turnoverDays',
  ],
  capacityDetails: ['extraGuestFee'],
  vrboSettings: ['bookingType', 'cancellationPolicy'],
};
