import { useContext, useEffect } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

import {
  FeeAmountType,
  FeeScopeType,
  QuoteOverrideFeeType,
  QuoteOverrides,
  QuoteResponse,
} from 'models/Quote';
import { useFormContext, useWatch } from 'react-hook-form';
import useAppUser from 'hooks/useAppUser';
import { getUnitType } from 'utils/lead/leadUtils';
import { format } from 'date-fns';
import { HasOrderItems } from 'models/hasOrderItems/HasOrderItems';
import { prepareOrderItems } from 'utils/quote/quoteUtils';
import useFeatures from 'hooks/useFeatures';
import LeadModalContext from './LeadModalContext';

const isQuoteNeedRefresh = (dirtyFields) => {
  if (dirtyFields?.quote) return true;
  if (dirtyFields?.propertyUid) return true;
  if (dirtyFields?.checkInDate) return true;
  if (dirtyFields?.checkOutDate) return true;
  if (dirtyFields?.adultCount) return true;
  if (dirtyFields?.childrenCount) return true;
  if (dirtyFields?.force100PercentPaymentAtReservation) return true;
  return false;
};

const getQuoteOverridesValuesBasedOnOrder = (
  order: HasOrderItems,
  dirtyFields,
): QuoteOverrides => {
  if (!order) return null;

  if (dirtyFields && Object.keys(dirtyFields).length === 0) {
    return null;
  }

  if (
    dirtyFields?.propertyUid ||
    dirtyFields?.adultCount ||
    dirtyFields?.childrenCount ||
    dirtyFields?.checkInDate ||
    dirtyFields?.checkOutDate
  ) {
    return null;
  }

  const overrides: QuoteOverrides = {
    rent: {
      rentBaseNetPrice: order.rent?.rentBaseNetPrice,
      extraGuestsBaseNetPrice: order.rent?.extraGuestsBaseNetPrice,
      taxationRate: dirtyFields?.quote?.rent?.taxationRate
        ? order?.rent?.taxationRate
        : null,
      taxAmount: dirtyFields?.quote?.rent?.taxAmount
        ? order.rent?.taxAmount
        : null,
    },
    securityDeposit: order.securityDeposit,
    totalPrice: order.totalAmount,
    force100PercentPaymentAtReservation:
      order.force100PercentPaymentAtReservation,
    fees: {
      cleaningFee: {
        ...order?.fees?.cleaningFee,
      },
      otherFees: order?.fees?.otherFees.map((fee) => ({
        name: fee.name,
        taxationRate: fee.taxationRate,
        netPrice: fee.netPrice,
        amount: null,
        amountType: FeeAmountType.AMOUNT,
        scope: FeeScopeType.PER_STAY,
        type: QuoteOverrideFeeType.CUSTOM,
        isOptional: fee.isOptional,
        enabled: fee.enabled,
        feeId: fee.feeId,
        feeUid: fee.feeUid,
        removed: fee.removed,
      })),
    },
  };

  return overrides;
};

const useWatchQuoteData = () => {
  const { availableProperties, setOverrides, setPropertyFees, lead } =
    useContext(LeadModalContext);
  const { agencyUid } = useAppUser();
  const {
    reset,
    formState: { defaultValues, isDirty, dirtyFields },
  } = useFormContext();
  const { allowNewLeadModalWebapp } = useFeatures();
  const {
    quote,
    uid: leadUid,
    checkInDate,
    checkOutDate,
    adultCount,
    childrenCount,
    propertyUid,
  } = useWatch();

  const overridesAdjusted = getQuoteOverridesValuesBasedOnOrder(
    quote,
    dirtyFields,
  );

  const { data: newQuote, isFetching: isNewQuoteLoading } = useAppQuery(
    [
      `get-quote-${leadUid}`,
      overridesAdjusted,
      leadUid,
      checkInDate,
      checkOutDate,
      adultCount,
      childrenCount,
      propertyUid,
    ],
    async () => {
      const unitType = getUnitType(availableProperties, propertyUid);
      const response = await API.post<QuoteResponse>('/api/v3/quotes', {
        leadUid: leadUid || undefined,
        overrides: overridesAdjusted,
        agencyUid,
        propertyUid: unitType?.uid || propertyUid,
        checkInDate: format(checkInDate, 'yyyy-MM-dd'),
        checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
        guests: parseInt(adultCount, 10) + parseInt(childrenCount, 10),
      });
      return response.data.quote;
    },
    {
      enabled:
        !!agencyUid &&
        !!propertyUid &&
        allowNewLeadModalWebapp &&
        ((isDirty && isQuoteNeedRefresh(dirtyFields)) || !quote),
    },
  );

  useEffect(() => {
    if (newQuote) {
      const newOrder = prepareOrderItems(newQuote, lead);
      const overrides = getQuoteOverridesValuesBasedOnOrder(quote, dirtyFields);

      setOverrides({ ...overrides });
      setPropertyFees(
        newQuote.overrides.fees.otherFees.filter((fee) => fee.feeUid !== null),
      );

      reset(
        {
          ...defaultValues,
          propertyUid,
          checkInDate,
          checkOutDate,
          adultCount,
          childrenCount,
          quote: newOrder,
        },
        {
          keepDirty: false,
        },
      );
    }
  }, [newQuote]);

  return {
    newQuote,
    isNewQuoteLoading,
  };
};

export default useWatchQuoteData;
