import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { CustomAmenity } from 'models/Amenity';
import { useTranslation } from 'react-i18next';

const ActionsCell = ({ row }: { row: Row<CustomAmenity> }) => {
  const { t } = useTranslation();

  // TODO: edit/delete to be implemented...

  return (
    <>
      <Button bsStyle="link">{t('common.edit')}</Button>
      <Button bsStyle="link">{t('common.delete')}</Button>
    </>
  );
};

export default ActionsCell;
