import { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { format, isDate } from 'date-fns';
import { FormContextParts } from 'components/forms/form/Form.types';
import useNotify from 'hooks/useNotify';
import { getErrorMessage } from 'utils/axiosExceptionUtils';
import {
  getFormDefaultValues,
  PropertyMainSettingsUpdatePayload,
  PropertySettingsMainTabFormValues,
} from './PropertySettingsMainTab.schema';
import useUpdatePropertyMainSettings from './useUpdatePropertyMainSettings';
import { CHECK_IN_TIME_END_FLEXIBLE } from './sections/airbnbSettings/AirbnbSettings.constants';

const fieldValuesConverters = {
  airbnbSettings: {
    nonRefundableRateDiscount: (
      value: PropertySettingsMainTabFormValues['airbnbSettings']['nonRefundableRateDiscount'],
    ) => {
      return typeof value === 'number' ? value / 100 : value;
    },
  },
  bookingSettings: {
    percentageUponReservation: (
      value: PropertySettingsMainTabFormValues['bookingSettings']['percentageUponReservation'],
    ) => {
      return value / 100;
    },
    daysOfTheWeekToCheckInOn: (
      value: PropertySettingsMainTabFormValues['bookingSettings']['daysOfTheWeekToCheckInOn'],
    ) =>
      Object.entries(value)
        .filter(([_day, isChecked]) => isChecked)
        .map(([day]) => day),
  },
  miscInfo: {
    rentalLicenseExpirationDate: (
      value: PropertySettingsMainTabFormValues['miscInfo']['rentalLicenseExpirationDate'],
    ) => (value && isDate(value) ? format(value, 'yyyy-MM-dd') : null),
  },
};

function appendMutuallyDependentFields(
  payload: PropertyMainSettingsUpdatePayload,
  formValues: PropertySettingsMainTabFormValues,
) {
  if (payload.propertyDetails?.propertySize !== undefined) {
    payload.propertyDetails.propertySizeUnit =
      formValues.propertyDetails.propertySizeUnit;
  } else if (payload.propertyDetails?.propertySizeUnit !== undefined) {
    payload.propertyDetails.propertySize =
      formValues.propertyDetails.propertySize;
  }

  // If `checkInTimeEnd` was changed
  if (payload.airbnbSettings?.checkInTimeEnd !== undefined) {
    const { checkInTimeEnd } = formValues.airbnbSettings;

    if (checkInTimeEnd === CHECK_IN_TIME_END_FLEXIBLE) {
      payload.airbnbSettings.checkInTimeEnd = null;
      payload.airbnbSettings.checkInTimeEndFlexible = true;
    } else {
      const checkInTimeEndParsed = Number.parseInt(checkInTimeEnd, 10);
      payload.airbnbSettings.checkInTimeEnd = checkInTimeEndParsed;
      payload.airbnbSettings.checkInTimeEndFlexible = false;
    }
  }

  return payload;
}

const usePropertyMainSettingsFormSubmit = (propertyUid: string) => {
  const { notifyError, notifySuccess } = useNotify();
  const { updatePropertyMainSettings } = useUpdatePropertyMainSettings();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return async (
    formValues: PropertySettingsMainTabFormValues,
    _event: BaseSyntheticEvent | undefined,
    {
      formState: { dirtyFields },
      reset,
    }: FormContextParts<PropertySettingsMainTabFormValues>,
  ) => {
    if (Object.keys(dirtyFields).length === 0) {
      return;
    }

    const payload: PropertyMainSettingsUpdatePayload = Object.entries(
      dirtyFields,
    ).reduce((acc, [sectionName, sectionDirtyFields]) => {
      return {
        ...acc,
        [sectionName]: Object.entries(sectionDirtyFields)
          .filter(([_, isDirty]) => isDirty)
          .reduce((sectionAcc, [fieldName]) => {
            const fieldValue = formValues[sectionName][fieldName];
            const fieldValueConverter =
              fieldValuesConverters[sectionName]?.[fieldName];

            return {
              ...sectionAcc,
              [fieldName]: fieldValueConverter
                ? fieldValueConverter(fieldValue)
                : fieldValue,
            };
          }, {}),
      };
    }, {});

    appendMutuallyDependentFields(payload, formValues);

    await updatePropertyMainSettings({ propertyUid, payload })
      .then(async ({ response }) => {
        const apiErrorCode = response?.data?.apiErrorCode;
        const apiErrorMessage = response?.data?.apiErrorMessage;

        if (apiErrorCode || apiErrorMessage) {
          notifyError(
            getErrorMessage({
              apiErrorCode,
              apiErrorMessage,
              baseKey: 'pageProperty.apiErrorCodes.',
              t,
            }),
          );
          return;
        }

        const queryKey = ['propertyMainSettingsData', propertyUid];
        await queryClient.invalidateQueries(queryKey).then(() => {
          const updatedFormValues = getFormDefaultValues(
            queryClient.getQueryData(queryKey),
          );

          reset(updatedFormValues);

          notifySuccess(t('pageProperty.mainSettings.updateSuccess'));
        });
      })
      .catch(({ response: { data } }) => {
        notifyError(
          getErrorMessage({
            ...data,
            baseKey: 'pageProperty.apiErrorCodes.',
            t,
          }),
        );
      });
  };
};

export default usePropertyMainSettingsFormSubmit;
