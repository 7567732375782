import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useFetchAgencyChannelSettings from 'components/domain/channelSettings/useFetchAgencyChannelSettings';
import LeadChannelIcon from 'components/domain/lead/LeadChannelIcon';
import RadioGroupField from 'components/forms/radioGroupField/RadioGroupField';
import SelectField from 'components/forms/selectField/SelectField';
import TextField from 'components/forms/textField/TextField';
import { AirbnbCancellationPolicy } from 'models/AirbnbCancellationPolicy';
import { AirbnbCheckInOptions } from 'models/AirbnbCheckInOptions';
import { AirbnbGuestRequirements } from 'models/AirbnbGuestRequirements';
import { BOOKING_TYPES } from 'models/BookingType';
import { Channel } from 'models/Channels';
import { LeadChannel } from 'models/Leads';
import { PropertyListingTypes } from 'models/PropertyListingTypes';
import {
  FormSectionColumn,
  SectionTitleContainer,
} from 'pages/property/common/Common.styles';
import PropertyFormFieldLabel from 'pages/property/common/PropertyFormFieldLabel';
import { range } from 'utils/arrayUtils';
import { PropertyDetailsCollapseStyled } from '../propertyDetails/PropertyDetails.styles';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { CHECK_IN_TIME_END_FLEXIBLE } from './AirbnbSettings.constants';
import {
  NonRefundableRateDiscountContainer,
  ViewAirbnbResolutionsStyled,
} from './AirbnbSettings.styles';
import NonRefundableRateDiscountField from './NonRefundableRateDiscountField';
import AllowBookingRequestField from './AllowBookingRequestField';

const AirbnbSettings = () => {
  const { t } = useTranslation();
  const { getCopyAddonIcon } = useCopyToClipboard();
  const {
    airbnbSettings: { airbnbId },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const { channelSettings, isFetching: isLoadingChannelSettings } =
    useFetchAgencyChannelSettings(true);

  const isAirbnbActive = !!channelSettings?.find(
    ({ enumId }) => enumId === Channel.airbnb,
  )?.isActive;

  if (!isAirbnbActive || isLoadingChannelSettings) {
    return null;
  }

  const bookingTypeOptions = [
    BOOKING_TYPES.BOOKING_REQUEST,
    BOOKING_TYPES.INSTANT_BOOKING,
  ].map((value) => ({
    label: t(`common.bookingType.${value}`),
    value,
  }));

  const listingTypeOptions = [
    PropertyListingTypes.ENTIREHOME,
    PropertyListingTypes.SHAREDROOM,
    PropertyListingTypes.PRIVATEROOM,
  ].map((value) => ({
    label: t(
      `pageProperty.mainSettings.airbnbSettings.listingTypeOptions.${value}`,
    ),
    value,
  }));

  const guestRequirementsOptions = Object.values(AirbnbGuestRequirements).map(
    (value) => ({
      label: t(
        `pageProperty.mainSettings.airbnbSettings.guestRequirementsOptions.${value}`,
      ),
      value,
    }),
  );

  const cancellationPolicyOptions = Object.values(AirbnbCancellationPolicy).map(
    (value) => ({
      label: t(
        `pageProperty.mainSettings.airbnbSettings.cancellationPolicyOptions.${value}`,
      ),
      value,
    }),
  );

  const checkInTimeStartOptions = [false, true].map((value) => ({
    label: t(
      `pageProperty.mainSettings.airbnbSettings.checkInTimeStartOptions.${value}`,
    ),
    value: `${value}`,
  }));

  const checkInTimeEndOptions = [
    {
      label: t(
        'pageProperty.mainSettings.airbnbSettings.checkInTimeEndFlexible',
      ),
      value: CHECK_IN_TIME_END_FLEXIBLE,
    },
    ...range(9, 27).map((value) => ({ label: `${value}`, value })),
  ];

  const checkInOptions = Object.values(AirbnbCheckInOptions).map((value) => ({
    label: t(
      `pageProperty.mainSettings.airbnbSettings.checkInOptionOptions.${value}`,
    ),
    value,
  }));

  const nonRefundableRateOptions = [
    { label: t('common.disabled'), value: 'false' },
    { label: t('common.enabled'), value: 'true' },
  ];

  const sectionTitle = (
    <SectionTitleContainer>
      <LeadChannelIcon channel={LeadChannel.AIRBNB} />
      {t('pageProperty.mainSettings.airbnbSettings.sectionTitle')}
    </SectionTitleContainer>
  );

  return (
    <PropertyDetailsCollapseStyled
      data-testid="airbnb-settings"
      defaultExpanded
      label={sectionTitle}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.airbnbSettings.airbnbId')}
              name="airbnbSettings.airbnbId"
              colSmInput={7}
              colSmLabel={5}
              disabled
              rightAddons={[getCopyAddonIcon(airbnbId)]}
            />
            <SelectField
              label={t('pageProperty.mainSettings.airbnbSettings.listingType')}
              name="airbnbSettings.listingType"
              options={listingTypeOptions}
              colSmInput={7}
              colSmLabel={5}
            />
            <SelectField
              label={
                <PropertyFormFieldLabel name="airbnbSettings.bookingType">
                  {t('pageProperty.mainSettings.airbnbSettings.bookingType')}
                </PropertyFormFieldLabel>
              }
              name="airbnbSettings.bookingType"
              options={bookingTypeOptions}
              colSmInput={7}
              colSmLabel={5}
            />
            <AllowBookingRequestField />
            <SelectField
              label={t(
                'pageProperty.mainSettings.airbnbSettings.guestRequirements',
              )}
              name="airbnbSettings.guestRequirements"
              options={guestRequirementsOptions}
              colSmInput={7}
              colSmLabel={5}
            />
            <SelectField
              label={
                <PropertyFormFieldLabel name="airbnbSettings.cancellationPolicy">
                  {t(
                    'pageProperty.mainSettings.airbnbSettings.cancellationPolicy',
                  )}
                </PropertyFormFieldLabel>
              }
              name="airbnbSettings.cancellationPolicy"
              options={cancellationPolicyOptions}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={
                <Trans
                  i18nKey="pageProperty.mainSettings.airbnbSettings.cancellationPolicyTooltip"
                  components={{
                    link1: (
                      <a
                        aria-label="Hostfully documentation"
                        href="https://help.hostfully.com/en/articles/1598674-activate-airbnb-channel#h_e72e9e0c72"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              }
            />
          </FormSectionColumn>
          <FormSectionColumn>
            <RadioGroupField
              label={
                <PropertyFormFieldLabel name="airbnbSettings.checkInTimeStartFlexible">
                  {t(
                    'pageProperty.mainSettings.airbnbSettings.checkInTimeStart',
                  )}
                </PropertyFormFieldLabel>
              }
              name="airbnbSettings.checkInTimeStartFlexible"
              options={checkInTimeStartOptions}
              colSmInput={7}
              colSmLabel={5}
              horizontal
            />
            <SelectField
              label={
                <PropertyFormFieldLabel name="airbnbSettings.checkInTimeEnd">
                  {t('pageProperty.mainSettings.airbnbSettings.checkInTimeEnd')}
                </PropertyFormFieldLabel>
              }
              name="airbnbSettings.checkInTimeEnd"
              options={checkInTimeEndOptions}
              colSmInput={7}
              colSmLabel={5}
              rightTooltip={t(
                'pageProperty.mainSettings.airbnbSettings.checkInTimeEndTooltip',
              )}
            />
            <SelectField
              label={t(
                'pageProperty.mainSettings.airbnbSettings.checkInOption',
              )}
              name="airbnbSettings.checkInOption"
              options={checkInOptions}
              colSmInput={7}
              colSmLabel={5}
            />
            <RadioGroupField
              label={
                <PropertyFormFieldLabel name="airbnbSettings.nonRefundableRateEnabled">
                  {t(
                    'pageProperty.mainSettings.airbnbSettings.nonRefundableRate',
                  )}
                </PropertyFormFieldLabel>
              }
              name="airbnbSettings.nonRefundableRateEnabled"
              options={nonRefundableRateOptions}
              colSmInput={7}
              colSmLabel={5}
              horizontal
            />
            <NonRefundableRateDiscountContainer
              xs={8}
              xsOffset={4}
              sm={7}
              smOffset={5}
            >
              <NonRefundableRateDiscountField />
            </NonRefundableRateDiscountContainer>
            <ViewAirbnbResolutionsStyled
              data-testid="view-airbnb-resolutions"
              bsStyle="link"
              href="#/channels/airbnb/resolutions"
            >
              {t(
                'pageProperty.mainSettings.airbnbSettings.viewAirbnbResolutions',
              )}
            </ViewAirbnbResolutionsStyled>
          </FormSectionColumn>
        </Row>
      </fieldset>
    </PropertyDetailsCollapseStyled>
  );
};

export default AirbnbSettings;
