import CustomAmenities from './sections/customAmenities/CustomAmenities';

const PropertySettingsAmenitiesTab = () => {
  return (
    <div>
      {/* TODO all amenities section */}
      <CustomAmenities />
    </div>
  );
};

export default PropertySettingsAmenitiesTab;
