import { TabContainer } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

export const PropertySettingsTabContainer = styled(TabContainer)`
  .nav > li {
    text-align: end;

    &.active {
      background-color: #eee;
      border-right: 3px solid ${theme.colors.primary};
    }

    &:not(.active) > a:focus {
      background-color: #fff;
      outline: none;
    }
  }
`;
