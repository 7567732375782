import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import Switch from 'components/switch/Switch';
import useNotify from 'hooks/useNotify';
import useDeactivateModal from 'components/domain/property/modal/confirmation/useDeactivateModal';
import useActivatePropertyMutation from '../useActivatePropertyMutation';
import PropertyStatusBadge from './PropertyStatusBadge';
import { PropertyStatusStyled } from './PropertyStatus.styles';
import { useUpdateFetchPropertyQuery } from './useUpdateFetchPropertyQuery';
import { PropertyForPropertyStatus } from './PropertyStatus.types';

interface PropertyStatusProps {
  property: PropertyForPropertyStatus;
}

const PropertyStatus = ({ property }: PropertyStatusProps) => {
  const { t } = useTranslation();
  const { notifySuccess } = useNotify();
  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);

  const { uid: propertyUid } = property;

  const { updateFetchPropertyQuery } = useUpdateFetchPropertyQuery({
    propertyUid,
  });

  const { openDeactivateModal, isDeactivatingProperty } = useDeactivateModal({
    onSuccess: () => {
      updateFetchPropertyQuery();
      setIsChecked(false);
    },
  });

  const { mutate: activateProperty, isLoading: isActivatingProperty } =
    useActivatePropertyMutation({
      onSuccess: () => {
        const name = property?.name;

        updateFetchPropertyQuery();
        setIsChecked(true);
        notifySuccess(
          t('pageProperties.actions.activationSuccess', {
            name: name?.length > 30 ? `${name.slice(0, 30)}...` : name,
          }),
        );
      },
    });

  const isMutating = isActivatingProperty || isDeactivatingProperty;

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isInputChecked = event.target.checked;

    if (isInputChecked) {
      activateProperty({ uid: propertyUid });
    } else {
      openDeactivateModal({ uid: propertyUid, name: property.name });
    }
  };

  return (
    <PropertyStatusStyled>
      {isMutating ? (
        <LoadingPlaceholder width="56px" linesCount={1.7} />
      ) : (
        <PropertyStatusBadge isActive={property?.isActive} />
      )}

      <Switch
        data-testid="property-status-switch"
        disabled={isMutating}
        onChange={handleStatusChange}
        checked={isChecked ?? property?.isActive ?? true}
        defaultChecked
      />
    </PropertyStatusStyled>
  );
};

export default PropertyStatus;
