import { useTranslation } from 'react-i18next';
import BulkSaveIconSvg from 'assets/images/icons/bulk-save.svg?react';
import Tooltip from 'components/tooltip/Tooltip';
import { BulkSaveIconWithTooltipWrapperStyled } from './BulkSaveIconWithTooltip.styles';

const BulkSaveIconWithTooltip = () => {
  const { t } = useTranslation();
  return (
    <BulkSaveIconWithTooltipWrapperStyled>
      <Tooltip id="bulk-save-tooltip" text={t('pageProperty.bulkSaveTooltip')}>
        <BulkSaveIconSvg data-testid="bulk-edit-icon" />
      </Tooltip>
    </BulkSaveIconWithTooltipWrapperStyled>
  );
};

export default BulkSaveIconWithTooltip;
