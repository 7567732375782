import Button from 'components/button/Button';
import { Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CustomAmenitiesHeaderContainer } from './CustomAmenities.styles';

const CustomAmenitiesHeader = () => {
  const { t } = useTranslation();

  return (
    <CustomAmenitiesHeaderContainer>
      <h3>{t('pageProperty.amenities.customAmenities.title')}</h3>

      <Button bsStyle="primary" onClick={() => {}}>
        <Glyphicon glyph="plus" />
        {t('pageProperty.amenities.customAmenities.add')}
      </Button>
    </CustomAmenitiesHeaderContainer>
  );
};

export default CustomAmenitiesHeader;
