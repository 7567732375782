import CustomAmenitiesHeader from './CustomAmenitiesHeader';
import CustomAmenitiesTable from './CustomAmenitiesTable';

const CustomAmenities = () => {
  return (
    <section>
      <CustomAmenitiesHeader />
      <CustomAmenitiesTable />
    </section>
  );
};

export default CustomAmenities;
