import { FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import UploadField from 'components/forms/fileField/UploadField/UploadField';
import UploadFieldFileList from 'components/forms/fileField/UploadField/UploadFieldFileList';
import ImagePreviewListItem from 'components/forms/fileField/UploadField/UploadFieldFileListItem/ImagePreviewListItem';
import useCurrentSelectedFile from './useCurrentSelectedFile';
import useUploadFileMutation from './useUploadFileMutation';
import { PropertyPhotoFile } from './PropertyDetails.types';

const PropertyDetailsUploadField = () => {
  const { t } = useTranslation();
  const { trigger, setValue } = useFormContext();
  const { currentFile, setCurrentFile } = useCurrentSelectedFile();

  const { mutate: uploadFile, isLoading: isUploading } = useUploadFileMutation({
    onSuccess: (savedFile) => setCurrentFile(savedFile),
    onError: () => setCurrentFile({ uri: null }),
  });

  const handleOnChange: FormEventHandler<HTMLInputElement> = async (event) => {
    const fileList = Array.from((event.target as HTMLInputElement).files);

    setValue('propertyDetails.internalThumbnail', fileList);

    const isValid = await trigger('propertyDetails.internalThumbnail');

    if (!fileList.length || !isValid) {
      setValue('propertyDetails.internalThumbnail', null);
      return;
    }

    const [file] = fileList;
    setCurrentFile({
      uri: '',
      uploadItemName: file.name,
      sizeInBytes: file.size,
      contentType: '',
    });
    uploadFile(file);
  };

  const handleOnDelete = () => {
    setCurrentFile({ uri: null });
  };

  return (
    <UploadField
      label={t('pageProperty.mainSettings.internalThumbnail')}
      name="propertyDetails.internalThumbnail"
      className="attach-thumbnail-button"
      accept="image/*"
      onChange={handleOnChange}
      colSmInput={7}
      colSmLabel={5}
    >
      {currentFile && (
        <UploadFieldFileList
          files={[currentFile]}
          getName={() => ''}
          getUrl={(file) => file.uri}
          isDisabled={isUploading}
          isLoading={isUploading}
          onDelete={handleOnDelete}
          ListItemComponent={ImagePreviewListItem<PropertyPhotoFile>}
        />
      )}
    </UploadField>
  );
};

export default PropertyDetailsUploadField;
