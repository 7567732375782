import styled from 'styled-components';
import { PropertyStatusStyled } from 'components/domain/property/status/PropertyStatus.styles';

export const PropertySettingsMainTabStyled = styled.div`
  display: flex;
  flex-direction: column;

  ${PropertyStatusStyled} {
    align-self: flex-end;
  }
`;
