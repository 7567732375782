import { SortingState } from '@tanstack/react-table';
import { ExpectedQueryData } from 'components/table/hooks/useServerFilteredTable';
import { getSortParam } from 'components/table/utils/sorting';
import {
  CustomAmenityObjectTypes,
  CustomAmenity,
  GetPropertyCustomAmenitiesResponse,
} from 'models/Amenity';
import API from 'services/API';

export const PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY =
  'propertyCustomAmenities';

export const propertyCustomAmenitiesQuery = ({
  propertyUid,
  pageIndex,
  sorting,
  pageSize,
}: {
  propertyUid: string;
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}) => ({
  queryKey: [
    PROPERTIES_CUSTOM_AMENITIES_BASE_QUERY_KEY,
    propertyUid,
    { pageIndex, sorting },
  ],
  queryFn: async (): Promise<ExpectedQueryData<CustomAmenity>> => {
    const sort = getSortParam(sorting, {
      name: 'SORT_BY_NAME',
    });

    const response = await API.get<GetPropertyCustomAmenitiesResponse>(
      // TODO: change to internal paginated endpoint in PMP-10827
      '/api/v3/custom-amenities',
      {
        params: {
          objectUid: propertyUid,
          objectType: CustomAmenityObjectTypes.PROPERTY,
          _limit: pageSize,
          ...(pageIndex > 0
            ? { _cursor: btoa(`{"offset":${pageIndex * pageSize + 1}}`) }
            : {}),
          ...(sort ? { sort: sort.sort, sortDirection: sort.direction } : {}),
        },
      },
    );

    const { customAmenities, _metadata: metadata } = response.data;

    return {
      metadata,
      data: customAmenities,
    };
  },
  enabled: !!propertyUid,
});
