import { Namespace, TFunction } from 'react-i18next';
import { DeepPartial } from 'react-hook-form';
import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import { AirbnbCancellationPolicy } from 'models/AirbnbCancellationPolicy';
import { AirbnbCheckInOptions } from 'models/AirbnbCheckInOptions';
import { AirbnbGuestRequirements } from 'models/AirbnbGuestRequirements';
import { AREA_UNIT_TYPE } from 'models/AreaUnitType';
import { BOOKING_TYPES, BookingType } from 'models/BookingType';
import { BOOKING_WINDOWS } from 'models/BookingWindow';
import { VrboCancellationPolicy } from 'models/Channels';
import { DAY_OF_WEEK } from 'models/DayOfWeek';
import { PropertyListingTypes } from 'models/PropertyListingTypes';
import {
  basePropertyAddressSchema,
  propertyCapacityDetailsSchema,
  propertyDetailsSchema,
} from '../common/Common.schema';
import {
  AirbnbSettings,
  BookingSettings,
  PropertyDetails,
  PropertyMainSettingsData,
} from './PropertySettingsMainTab.types';
import { CHECK_IN_TIME_END_FLEXIBLE } from './sections/airbnbSettings/AirbnbSettings.constants';
import { VRBO_AVAILABLE_BOOKING_TYPES } from './sections/vrboSettings/PropertyVrboSettings.constants';

export const propertySettingsMainTabSchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    airbnbSettings: Yup.object({
      airbnbId: Yup.string().nullable().optional(),
      allowBookingRequestAboveMaximumStay: Yup.boolean().optional().nullable(),
      allowBookingRequestWithinBookingLeadTime: Yup.boolean()
        .optional()
        .nullable(),
      bookingType: Yup.string()
        .oneOf([null, ...Object.values(BOOKING_TYPES)])
        .nullable(),
      cancellationPolicy: Yup.string()
        .oneOf([null, ...Object.values(AirbnbCancellationPolicy)])
        .nullable(),
      checkInOption: Yup.string()
        .oneOf([null, ...Object.values(AirbnbCheckInOptions)])
        .nullable(),
      checkInTimeEnd: Yup.string(),
      checkInTimeStartFlexible: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      guestRequirements: Yup.string()
        .oneOf([null, ...Object.values(AirbnbGuestRequirements)])
        .nullable(),
      listingType: Yup.string()
        .oneOf([null, ...Object.values(PropertyListingTypes)])
        .nullable(),
      nonRefundableRateEnabled: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .optional()
        .nullable(),
      nonRefundableRateDiscount: Yup.number()
        .optional()
        .nullable()
        .when('nonRefundableRateEnabled', {
          is: true,
          then: (schema) =>
            schema
              .useNaNAsNull()
              .useNegativeZeroAsNegative()
              .min(0.01)
              .max(100)
              .required(),
        }),
    }),
    bookingSettings: Yup.object({
      bookingLeadTime: Yup.number(),
      bookingType: Yup.string().oneOf(Object.values(BOOKING_TYPES)),
      bookingWindow: Yup.number().oneOf(Object.values(BOOKING_WINDOWS)),
      checkInTime: Yup.number(),
      checkoutTime: Yup.number(),
      dayOfWeekMinimumStay: Yup.object({
        [DAY_OF_WEEK.MONDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.TUESDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.WEDNESDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.THURSDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.FRIDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.SATURDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
        [DAY_OF_WEEK.SUNDAY]: Yup.number()
          .optional()
          .nullable()
          .integer()
          .min(0)
          .useNegativeZeroAsNegative()
          .useNaNAsNull(),
      }),
      daysOfTheWeekToCheckInOn: Yup.object({
        [DAY_OF_WEEK.MONDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.TUESDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.WEDNESDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.THURSDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.FRIDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.SATURDAY]: Yup.boolean().optional(),
        [DAY_OF_WEEK.SUNDAY]: Yup.boolean().optional(),
      }),
      fullPaymentTiming: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      maximumStay: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      minimumStay: Yup.number()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .when(['maximumStay'], {
          is: (value: number) => value > 0,
          then: (schema) =>
            schema.max(
              Yup.ref('maximumStay'),
              t(
                'pageProperty.mainSettings.bookingSettings.minimumStayMinValidation',
              ),
            ),
        })
        .required(),
      minimumWeekendStay: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .integer()
        .min(0)
        .optional()
        .nullable(),
      percentageUponReservation: Yup.number()
        .useNaNAsNull()
        .useNegativeZeroAsNegative()
        .min(0)
        .max(100)
        .integer()
        .optional()
        .nullable(),
      turnoverDays: Yup.number(),
    }),
    capacityDetails: propertyCapacityDetailsSchema(t),
    propertyAddress: basePropertyAddressSchema().shape({
      latitude: Yup.number()
        .min(
          -90,
          t('pageProperty.mainSettings.propertyAddress.validation.latitude'),
        )
        .max(
          90,
          t('pageProperty.mainSettings.propertyAddress.validation.latitude'),
        )
        .nullable()
        .transform((current, original) => (original === '' ? null : current)),
      longitude: Yup.number()
        .min(
          -180,
          t('pageProperty.mainSettings.propertyAddress.validation.longitude'),
        )
        .max(
          180,
          t('pageProperty.mainSettings.propertyAddress.validation.longitude'),
        )
        .nullable()
        .transform((current, original) => (original === '' ? null : current)),
    }),
    propertyDetails: propertyDetailsSchema(t),
    miscInfo: Yup.object({
      accountingId: Yup.string().nullable().optional(),
      externalId: Yup.string().nullable().optional(),
      rentalLicenseNumber: Yup.string().nullable().optional(),
      rentalLicenseExpirationDate: Yup.date().nullable().optional(),
      wifiNetwork: Yup.string().nullable().optional(),
      wifiPassword: Yup.string().nullable().optional(),
    }),
    vrboSettings: Yup.object({
      propertyId: Yup.string().nullable().optional(),
      bookingType: Yup.mixed<BookingType>()
        .oneOf(VRBO_AVAILABLE_BOOKING_TYPES)
        .required(),
      cancellationPolicy: Yup.mixed<VrboCancellationPolicy>().required(),
      showPropertyExactLocation: Yup.mixed<boolean | string>()
        .transform((value) => value === 'true')
        .required(),
    }),
  });

export type PropertySettingsMainTabFormValues = DeepPartial<
  Yup.InferType<ReturnType<typeof propertySettingsMainTabSchema>>
>;

export type PropertyMainSettingsUpdatePayload = Omit<
  PropertySettingsMainTabFormValues,
  'airbnbSettings'
> & {
  airbnbSettings?: Partial<AirbnbSettings>;
  bookingSettings?: Partial<BookingSettings>;
  propertyDetails?: Partial<PropertyDetails>;
};

export const getFormDefaultValues = (
  mainSettingsData: PropertyMainSettingsData,
): PropertySettingsMainTabFormValues => {
  // If property already exists, then return the data from the server
  if (mainSettingsData) {
    const {
      airbnbSettings: { checkInTimeEndFlexible, ...airbnbSettings },
      bookingSettings: { daysOfTheWeekToCheckInOn },
      ...restMainSettingsData
    } = mainSettingsData;

    return {
      ...restMainSettingsData,
      airbnbSettings: {
        ...airbnbSettings,
        checkInTimeEnd: checkInTimeEndFlexible
          ? CHECK_IN_TIME_END_FLEXIBLE
          : `${airbnbSettings.checkInTimeEnd}`,
        checkInTimeStartFlexible: `${airbnbSettings.checkInTimeStartFlexible}`,
        nonRefundableRateEnabled: `${airbnbSettings.nonRefundableRateEnabled}`,
        nonRefundableRateDiscount:
          typeof airbnbSettings.nonRefundableRateDiscount === 'number'
            ? airbnbSettings.nonRefundableRateDiscount * 100
            : airbnbSettings.nonRefundableRateDiscount,
      },
      bookingSettings: {
        ...mainSettingsData.bookingSettings,
        daysOfTheWeekToCheckInOn: {
          [DAY_OF_WEEK.MONDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.MONDAY,
          ),
          [DAY_OF_WEEK.TUESDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.TUESDAY,
          ),
          [DAY_OF_WEEK.WEDNESDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.WEDNESDAY,
          ),
          [DAY_OF_WEEK.THURSDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.THURSDAY,
          ),
          [DAY_OF_WEEK.FRIDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.FRIDAY,
          ),
          [DAY_OF_WEEK.SATURDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.SATURDAY,
          ),
          [DAY_OF_WEEK.SUNDAY]: daysOfTheWeekToCheckInOn.includes(
            DAY_OF_WEEK.SUNDAY,
          ),
        },
        percentageUponReservation:
          mainSettingsData.bookingSettings.percentageUponReservation * 100,
      },
      miscInfo: {
        ...mainSettingsData.miscInfo,
        rentalLicenseExpirationDate:
          mainSettingsData.miscInfo.rentalLicenseExpirationDate &&
          parseISO(mainSettingsData.miscInfo.rentalLicenseExpirationDate),
      },
      vrboSettings: {
        ...mainSettingsData.vrboSettings,
        showPropertyExactLocation: `${mainSettingsData.vrboSettings.showPropertyExactLocation}`,
      },
    };
  }

  return {
    airbnbSettings: {
      checkInTimeEnd: CHECK_IN_TIME_END_FLEXIBLE,
    },
    bookingSettings: {
      bookingLeadTime: 48,
      checkInTime: 15,
      checkoutTime: 11,
      daysOfTheWeekToCheckInOn: {
        [DAY_OF_WEEK.MONDAY]: false,
        [DAY_OF_WEEK.TUESDAY]: false,
        [DAY_OF_WEEK.WEDNESDAY]: false,
        [DAY_OF_WEEK.THURSDAY]: false,
        [DAY_OF_WEEK.FRIDAY]: false,
        [DAY_OF_WEEK.SATURDAY]: false,
        [DAY_OF_WEEK.SUNDAY]: false,
      },
      fullPaymentTiming: 45,
      maximumStay: 0,
      minimumStay: 1,
      minimumWeekendStay: 0,
      percentageUponReservation: 50,
    },
    propertyAddress: {
      countryCode: 'US',
      state: 'AL',
    },
    propertyDetails: {
      propertySizeUnit: AREA_UNIT_TYPE.SQUARE_METERS,
      propertyType: 'APARTMENT',
    },
    vrboSettings: {
      bookingType: BOOKING_TYPES.INSTANT_BOOKING,
      cancellationPolicy: VrboCancellationPolicy.NO_REFUND,
      showPropertyExactLocation: 'true',
    },
  };
};
