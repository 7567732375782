import * as Yup from 'yup';
import { Namespace, TFunction } from 'react-i18next';
import { AREA_UNIT_TYPE, AreaUnitType } from 'models/AreaUnitType';
import { MEDIA_TYPE } from 'utils/constants/files';

export const basePropertyAddressSchema = () =>
  Yup.object({
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string().nullable().optional(),
    city: Yup.string().required(),
    countryCode: Yup.string().required(),
    state: Yup.string().when('countryCode', {
      is: 'US',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    zipCode: Yup.string().required(),
  });

export const propertyCapacityDetailsSchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    baseGuests: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .integer()
      .positive()
      .required(),
    maxGuests: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .integer()
      .positive()
      .required()
      .when('baseGuests', {
        is: (value: number) => value > 0,
        then: (schema) =>
          schema.min(
            Yup.ref('baseGuests'),
            t('pageProperty.mainSettings.capacityDetails.validation.maxGuests'),
          ),
      }),
    extraGuestFee: Yup.number()
      .useNaNAsNull()
      .useNegativeZeroAsNegative()
      .min(0)
      .nullable()
      .notRequired(),
  });

export const propertyDetailsSchema = (t: TFunction<Namespace<'en'>>) =>
  Yup.object({
    floorCount: Yup.number().integer().optional().nullable().useNaNAsNull(),
    internalThumbnail: Yup.mixed().test({
      message: t('pageProperty.mainSettings.internalThumbnailInvalidType'),
      test: (fileList: FileList | File[] | null) => {
        if (!fileList?.length) return true;

        const [file] = Array.from(fileList);
        const isTypeValid = [
          MEDIA_TYPE.IMAGE_PNG,
          MEDIA_TYPE.IMAGE_JPEG,
          MEDIA_TYPE.IMAGE_GIF,
          MEDIA_TYPE.IMAGE_WEBP,
        ].includes(file.type);

        return isTypeValid;
      },
    }),
    internalThumbnailUrl: Yup.string().nullable().optional(),
    propertyName: Yup.string().min(8).max(49).required(),
    propertySize: Yup.number().useNegativeZeroAsNegative().min(0).required(),
    propertySizeUnit: Yup.mixed<AreaUnitType>().oneOf(
      Object.values(AREA_UNIT_TYPE),
    ),
    propertyType: Yup.string().required(),
    weblink: Yup.string().optional().nullable(),
  });
