import { Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextField from 'components/forms/textField/TextField';
import SelectField from 'components/forms/selectField/SelectField';
import StateSelectField from 'components/forms/stateSelectField/StateSelectField';
import { countriesListWithFlag } from 'utils/constants/countries';
import { FormSectionColumn } from '../../../common/Common.styles';
import { PropertySettingsMainTabFormValues } from '../../PropertySettingsMainTab.schema';
import { PropertyAddressCollapseStyled } from './PropertyAddress.styles';

const PropertyAddress = () => {
  const { uid: propertyUid } = useParams();
  const { t } = useTranslation();
  const {
    propertyAddress: { countryCode },
  } = useWatch<PropertySettingsMainTabFormValues>();

  const isEditMode = !!propertyUid;
  const isStateRequired = countryCode === 'US';

  return (
    <PropertyAddressCollapseStyled
      data-testid="property-address"
      defaultExpanded
      label={t('pageProperty.mainSettings.propertyAddress.sectionTitle')}
      variant="card"
    >
      <fieldset>
        <Row>
          <FormSectionColumn>
            <TextField
              label={t('pageProperty.mainSettings.propertyAddress.address1')}
              name="propertyAddress.addressLine1"
              colSmInput={7}
              colSmLabel={5}
              required
            />
            <TextField
              label={t('pageProperty.mainSettings.propertyAddress.address2')}
              name="propertyAddress.addressLine2"
              colSmInput={7}
              colSmLabel={5}
            />
            <TextField
              label={t('pageProperty.mainSettings.propertyAddress.city')}
              name="propertyAddress.city"
              colSmInput={7}
              colSmLabel={5}
              required
            />
            <TextField
              label={t('pageProperty.mainSettings.propertyAddress.zipCode')}
              name="propertyAddress.zipCode"
              colSmInput={7}
              colSmLabel={5}
              required
            />
          </FormSectionColumn>
          <FormSectionColumn>
            <StateSelectField
              countryCode={countryCode}
              defaultUsState="AL"
              label={t('pageProperty.mainSettings.propertyAddress.state')}
              name="propertyAddress.state"
              colSmInput={7}
              colSmLabel={5}
              required={isStateRequired}
            />
            <SelectField
              label={t('pageProperty.mainSettings.propertyAddress.country')}
              name="propertyAddress.countryCode"
              options={countriesListWithFlag}
              colSmInput={7}
              colSmLabel={5}
              required
            />
            {isEditMode && (
              <>
                <TextField
                  label={t(
                    'pageProperty.mainSettings.propertyAddress.latitude',
                  )}
                  name="propertyAddress.latitude"
                  colSmInput={7}
                  colSmLabel={5}
                  rightTooltip={t(
                    'pageProperty.mainSettings.propertyAddress.coordinatesTooltip',
                  )}
                />
                <TextField
                  label={t(
                    'pageProperty.mainSettings.propertyAddress.longitude',
                  )}
                  name="propertyAddress.longitude"
                  colSmInput={7}
                  colSmLabel={5}
                  rightTooltip={t(
                    'pageProperty.mainSettings.propertyAddress.coordinatesTooltip',
                  )}
                />
              </>
            )}
          </FormSectionColumn>
        </Row>
      </fieldset>
    </PropertyAddressCollapseStyled>
  );
};

export default PropertyAddress;
