import { BasePagingResponse, BaseResponse } from './_base';

export interface CustomAmenity {
  uid: string;
  name: string;
  objectUid: string;
  objectType: CustomAmenityObjectTypes;
}

export enum CustomAmenityObjectTypes {
  HOTEL = 'HOTEL',
  PROPERTY = 'PROPERTY',
}

export interface GetPropertyCustomAmenitiesResponse
  extends BaseResponse,
    BasePagingResponse {
  customAmenities: CustomAmenity[];
}
