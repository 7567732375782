import styled from 'styled-components';

export const CustomAmenitiesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  button {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    span.glyphicon {
      margin-right: 5px;
      font-size: 12px;
    }
  }
`;
