import { Row } from '@tanstack/react-table';
import Button from 'components/button/Button';
import { PropertyInternalFee } from 'models/PropertyFees';
import { useTranslation } from 'react-i18next';
import useDeleteModal from './useDeleteModal';

const ActionsCell = ({ row }: { row: Row<PropertyInternalFee> }) => {
  const { t } = useTranslation();

  const { openConfirmDeleteModal } = useDeleteModal({ row });

  // TODO: edit to be implemented...

  return (
    <>
      <Button bsStyle="link">{t('common.edit')}</Button>
      <Button
        bsStyle="link"
        data-testid="table-row-delete-button"
        onClick={openConfirmDeleteModal}
      >
        {t('common.delete')}
      </Button>
    </>
  );
};

export default ActionsCell;
