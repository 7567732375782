import { countryFlags } from 'utils/constants/flags';

export const CURRENCIES = [
  'AED',
  'ARS',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'GTQ',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NONE',
  'NZD',
  'PEN',
  'PHP',
  'PLN',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  'UYU',
  'VEF',
  'VND',
  'VUV',
  'JOD',
  'ZAR',
] as const;

export const currencyToFlagCode: {
  [key in CurrencyWithoutNone]: keyof typeof countryFlags;
} = {
  AED: 'AE',
  ARS: 'AR',
  AUD: 'AU',
  BGN: 'BG',
  BRL: 'BR',
  CAD: 'CA',
  CHF: 'CH',
  CLP: 'CL',
  CNY: 'CN',
  COP: 'CO',
  CZK: 'CZ',
  DKK: 'DK',
  EGP: 'EG',
  EUR: 'EU',
  GBP: 'GB',
  GTQ: 'GT',
  HKD: 'HK',
  IDR: 'ID',
  ILS: 'IL',
  INR: 'IN',
  JPY: 'JP',
  KRW: 'KR',
  MXN: 'MX',
  MYR: 'MY',
  NOK: 'NO',
  NZD: 'NZ',
  PEN: 'PE',
  PHP: 'PH',
  PLN: 'PL',
  RON: 'RO',
  RUB: 'RU',
  SAR: 'SA',
  SEK: 'SE',
  SGD: 'SG',
  THB: 'TH',
  TRY: 'TR',
  TWD: 'TW',
  USD: 'US',
  UYU: 'UY',
  VEF: 'VE',
  VND: 'VN',
  VUV: 'VU',
  JOD: 'JO',
  ZAR: 'ZA',
};

export const CURRENCIES_WITHOUT_NONE = CURRENCIES.filter(
  (currency) => currency !== 'NONE',
);

export type Currency = (typeof CURRENCIES)[number];

export type CurrencyWithoutNone = Exclude<Currency, 'NONE'>;
